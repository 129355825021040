import { message } from "antd";
import { Button } from "common/components/StyledElements";
import APIConstants from "common/constants/APIConstants";
import COLORS from "common/constants/Colors";
import { passwordRegex, phoneNumberRegex } from "common/constants/regex";
import { useAuthContext } from "common/contexts/AuthContext";
import LoginPagePharmacyImage from "common/icons/LoginPagePharmacyImage.png";
import NewEnneaLogo from "common/icons/NewEnneaLogo";
import { validateNumberInput } from "common/utils";
import { api } from "common/utils/APIMethods";
import { EventType, ReactGATracker } from "common/utils/analyticsTracker";
import { MESSAGE_TYPE, showMessage } from "common/utils/message";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BottomTextContainer,
  CustomError,
  InputElement,
  LoginBodyContainer,
  LoginDetailsIconsContainer,
  LoginDetailsInputContainer,
  SignInImageContainer,
  UserLoginButton,
  ValuemediLogoContainer
} from "routes/Login";

import LoginIcon from "common/icons/LoginPageLoginIcon.svg";
import LoginFooter from "routes/Login/LoginFooter";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const WelcomeToContainer = styled.div`
  color: ${COLORS.dark_teal};
  font-family: "Poppins";
  font-size: calc(3.91363rem * 0.7352);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: calc(-0.15656rem * 0.7352);
  margin-top: 0px;
`;

const RegisterAndLoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(30.75rem * 0.7352);
`;

const UpdatePassword = ({ sessionId, handleError }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { resetPassword } = APIConstants;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm({
    mode: "onChange",
    defaultValues: {
      password: "",
      passwordConfirm: ""
    }
  });

  const registerOptions = {
    password: {
      required: t("password_required"),
      pattern: {
        value: passwordRegex,
        message: t("password_error_message")
      }
    },
    passwordConfirm: {
      required: t("confirm_password_required"),
      validate: (value) =>
        value === getValues().password || t("password_not_match")
    }
  };

  const submitPassword = () =>
    api({
      url: resetPassword,
      method: "PUT",
      body: { sessionId, password: getValues().password }
    });

  const { isLoading, mutate } = useMutation(() => submitPassword(), {
    onSuccess: (response) => {
      showMessage(
        "password_update_successfully",
        MESSAGE_TYPE.successTranslate
      );
      navigate("/login", {
        state: { username: response.username },
        replace: true
      });
    },
    onError: (error) => {
      handleError(error.message);
    }
  });

  const handleSubmitPassword = (formData) => {
    ReactGATracker(EventType.CONFIRM_PASSWORD_ON_SUBMIT_CLICK);
    mutate(formData);
  };

  return (
    <>
      <LoginDetailsInputContainer>
        <InputElement
          name="password"
          type="password"
          {...register("password", registerOptions.password)}
          error={errors?.password}
          placeholder={t("enter_new_password")}
          onFocus={() => {
            ReactGATracker(EventType.CONFIRM_PASSWORD_NEW_PASSWORD_FIELD_CLICK);
          }}
        />
      </LoginDetailsInputContainer>
      {errors.password && <CustomError>{errors.password.message}</CustomError>}
      <LoginDetailsInputContainer>
        <InputElement
          name="passwordConfirm"
          type="password"
          {...register("passwordConfirm", registerOptions.passwordConfirm)}
          error={errors?.passwordConfirm}
          placeholder={t("re_enter_password")}
          onFocus={() => {
            ReactGATracker(EventType.CONFIRM_PASSWORD_FIELD_CLICK);
          }}
        />
      </LoginDetailsInputContainer>
      {errors.passwordConfirm && (
        <CustomError>{errors.passwordConfirm.message}</CustomError>
      )}
      <UserLoginButton
        primary="true"
        disabled={isLoading}
        loading={isLoading}
        onClick={handleSubmit(handleSubmitPassword)}
      >
        <Trans i18nKey="done" />
        <LoginDetailsIconsContainer src={LoginIcon} alt="verify" />
      </UserLoginButton>
    </>
  );
};

const VerifyOTP = ({
  sessionId,
  phoneNumber,
  isPhoneNumberLogin,
  sendingOTP,
  handleResendOTP,
  handleSuccess
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dispatch } = useAuthContext();
  const { otpValidate, phoneNumberLogin } = APIConstants;
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      otp: ""
    }
  });

  const registerOptions = {
    required: true,
    minLength: { value: 6 },
    maxLength: { value: 6 }
  };

  const getUserAuthDataFromPhoneNumberMutation = useMutation(
    (params) =>
      api({
        url: phoneNumberLogin,
        method: "POST",
        params
      }),
    {
      onSuccess: (response) => {
        dispatch({ type: "onLogin", payload: response });
        navigate("/dashboard", { replace: true });
      },
      onError: (error) => {
        showMessage(
          error?.message ?? "check_username_password",
          MESSAGE_TYPE.errorTranslate
        );
      }
    }
  );

  const { isLoading, mutate } = useMutation(
    (formData) =>
      api({
        url: `${otpValidate}/${parseInt(formData.otp, 10)}/${sessionId}`
      }),
    {
      onSuccess: (response) => {
        showMessage("otp_verify_successfull", MESSAGE_TYPE.successTranslate);
        if (isPhoneNumberLogin) {
          const params = {
            phoneNumber,
            otpSession: sessionId
          };
          getUserAuthDataFromPhoneNumberMutation.mutate(params);
        } else {
          handleSuccess({ sessionId: response.sessionId, mode: 2 });
        }
      },
      onError: (error) => {
        showMessage(
          error?.message ?? "otp_verify_unsuccessfull",
          MESSAGE_TYPE.errorTranslate
        );
      }
    }
  );

  const handleVerifyOTP = (formData) => {
    ReactGATracker(EventType.OTP_VERIFY_BUTTON_CLICK);
    mutate(formData);
  };

  return (
    <>
      <LoginDetailsInputContainer>
        <InputElement
          {...register("otp", registerOptions)}
          placeholder={t("enter_digit_otp")}
        />
      </LoginDetailsInputContainer>
      {errors.otp && (
        <CustomError>
          <Trans i18nKey="enter_valid_otp" />
        </CustomError>
      )}
      <BottomTextContainer>
        <Trans i18nKey="otp_not_received" />
        <Button
          style={{ borderWidth: 0 }}
          loading={sendingOTP}
          disabled={sendingOTP}
          onClick={handleResendOTP}
        >
          <Trans i18nKey="resend" />
        </Button>
      </BottomTextContainer>
      <UserLoginButton
        primary="true"
        loading={isLoading || getUserAuthDataFromPhoneNumberMutation.isLoading}
        disabled={isLoading || getUserAuthDataFromPhoneNumberMutation.isLoading}
        onClick={handleSubmit(handleVerifyOTP)}
      >
        <Trans i18nKey={isPhoneNumberLogin ? "login_to_valuemedi" : "verify"} />
        <LoginDetailsIconsContainer src={LoginIcon} alt="verify" />
      </UserLoginButton>
    </>
  );
};

const GenerateOTP = ({ phNumber, sendingOTP, handleGenerateOTP }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      phoneNumber: phNumber || ""
    }
  });
  const { t } = useTranslation();
  const registerOptions = {
    required: t("enter_phone"),
    pattern: {
      value: phoneNumberRegex,
      message: t("enter_valid_phone")
    }
  };

  return (
    <>
      <LoginDetailsInputContainer>
        <div>+91</div>
        <InputElement
          onKeyDown={validateNumberInput}
          {...register("phoneNumber", registerOptions)}
          error={errors?.phoneNumber}
          placeholder={t("enter_mobile")}
          onFocus={() => {
            ReactGATracker(EventType.FORGOT_PASSWORD_PHONE_NUMBER_FIELD_CLICK);
          }}
        />
      </LoginDetailsInputContainer>
      {errors.phoneNumber && (
        <CustomError>{errors.phoneNumber.message}</CustomError>
      )}
      <UserLoginButton
        primary="true"
        disabled={sendingOTP}
        loading={sendingOTP}
        onClick={handleSubmit(handleGenerateOTP)}
      >
        <Trans i18nKey="verify" />
        <LoginDetailsIconsContainer src={LoginIcon} alt="verify" />
      </UserLoginButton>
    </>
  );
};

const ResetPassword = () => {
  const locationProps = useLocation();
  const { otpGenerate } = APIConstants;
  const [mode, setMode] = useState(0);
  const sessionId = useRef("");
  const isPhoneNumberLoginRef = useRef(false);
  const phoneNumber = useRef(locationProps?.state?.phNumber || "");
  const navigate = useNavigate();

  useEffect(() => {
    const data = locationProps?.state;
    if (data?.isPhoneNumberLogin) {
      isPhoneNumberLoginRef.current = data.isPhoneNumberLogin;
      phoneNumber.current = data.phoneNumber;
      sessionId.current = data.sessionId;
      setMode(data.mode);
    }
  }, [locationProps?.state]);

  const handleSuccess = (response) => {
    sessionId.current = response.sessionId;
    setMode(response.mode);
  };

  const handleError = (errorMessage) => {
    message.error(errorMessage);
  };

  const { isLoading: sendingOTP, mutate } = useMutation(
    (formData) =>
      api({
        url: `${otpGenerate}/${parseInt(formData.phoneNumber, 10)}`
      }),
    {
      onSuccess: (response) => {
        showMessage("otp_send_successful", MESSAGE_TYPE.successTranslate);
        handleSuccess({ sessionId: response.sessionId, mode: 1 });
      },
      onError: (err) => {
        handleError(err.message);
      }
    }
  );

  const handleGenerateOTP = (formData) => {
    phoneNumber.current = formData.phoneNumber;
    ReactGATracker(EventType.FORGOT_PASSWORD_SEND_OTP_BUTTON_CLICK);
    mutate(formData);
  };

  const handleResendOTP = () => {
    ReactGATracker(EventType.OTP_RESEND_BUTTON_CLICK);
    mutate({ phoneNumber: phoneNumber.current });
  };

  const renderFormComponent = () => {
    if (mode === 1) {
      return (
        <VerifyOTP
          sessionId={sessionId.current}
          phoneNumber={phoneNumber.current}
          isPhoneNumberLogin={isPhoneNumberLoginRef.current}
          sendingOTP={sendingOTP}
          handleResendOTP={handleResendOTP}
          handleSuccess={handleSuccess}
        />
      );
    }
    if (mode === 2) {
      return (
        <UpdatePassword
          sessionId={sessionId.current}
          handleError={handleError}
        />
      );
    }
    return (
      <GenerateOTP
        phNumber={phoneNumber.current}
        sendingOTP={sendingOTP}
        handleGenerateOTP={handleGenerateOTP}
      />
    );
  };

  return (
    <MainContainer>
      <SignInImageContainer src={LoginPagePharmacyImage} about="LOGIN_IMAGE" />
      <LoginBodyContainer>
        <WelcomeToContainer>
          <Trans i18nKey="welcome_to" />
        </WelcomeToContainer>
        <ValuemediLogoContainer>
          <NewEnneaLogo />
        </ValuemediLogoContainer>
        {renderFormComponent()}
        <RegisterAndLoginContainer>
          <BottomTextContainer
            onClick={() => {
              ReactGATracker(EventType.REGISTER_BUTTON_CLICK);
              navigate("/signup");
            }}
          >
            <Trans i18nKey="create_new_account" />
          </BottomTextContainer>
          <BottomTextContainer
            onClick={() => {
              ReactGATracker(EventType.LANDING_ON_LOGIN_CLICK);
              navigate("/login");
            }}
          >
            <Trans i18nKey="go_to_login" />
          </BottomTextContainer>
        </RegisterAndLoginContainer>
        <LoginFooter />
      </LoginBodyContainer>
    </MainContainer>
  );
};

export default ResetPassword;
