import { supportNumber } from "common/constants";
import COLORS from "common/constants/Colors";
import LANGUAGES from "common/constants/language";
import RadioFalse from "common/icons/RadioFalse.svg";
import RadioTrue from "common/icons/RadioTrue.svg";
import { EventType, ReactGATracker } from "common/utils/analyticsTracker";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

const TermsElement = styled.u`
  height: 4%;
  cursor: pointer;
  margin-top: 2%;
  color: ${COLORS.primary_green};
`;

const SupportUs = styled.div`
  display: flex;
  justify-content: center;
`;

const Contact = styled.div`
  color: ${COLORS.primary_green};
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
`;

const SelectLanguageContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.94rem;
  width: calc(27.75rem * 0.7352);
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  ${(props) =>
    props.isPhoneNumberLogin &&
    css`
      margin-top: 3rem;
    `}
`;

const RadioButtonsContainer = styled.img`
  width: 1.75rem;
  height: 1.75rem;
  flex-shrink: 0;
`;

const RadioItemContainer = styled.div`
  display: flex;
  column-gap: 3px;
  width: 7.2rem;
  color: ${COLORS.dark_teal};
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  align-items: center;
  cursor: pointer;
`;

const RadioLanguageOptionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-left: 25px;
`;

const BottomTextContainer = styled.div`
  color: ${COLORS.dark_teal};
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  cursor: pointer;
`;

const ChangeLanguage = () => {
  const { i18n } = useTranslation();

  return (
    <SelectLanguageContainer>
      <BottomTextContainer>
        <Trans i18nKey="select_a_language" />
      </BottomTextContainer>
      <RadioLanguageOptionsContainer>
        {LANGUAGES?.map((lang) => (
          <RadioItemContainer
            key={lang.key}
            onClick={() => {
              if (lang.key === "en") {
                ReactGATracker(EventType.LOGIN_LANGUAGE_EN_BUTTON_CLICK);
              } else if (lang.key === "hi") {
                ReactGATracker(EventType.LOGIN_LANGUAGE_HI_BUTTON_CLICK);
              } else {
                ReactGATracker(EventType.LOGIN_LANGUAGE_TE_BUTTON_CLICK);
              }
              i18n.changeLanguage(lang.key).then((tn) => {
                tn(lang.key);
              });
            }}
          >
            <RadioButtonsContainer
              src={i18n.language === lang.key ? RadioTrue : RadioFalse}
              alt="RADIO"
            />
            {lang.title}
          </RadioItemContainer>
        ))}
      </RadioLanguageOptionsContainer>
    </SelectLanguageContainer>
  );
};

const LoginFooter = () => {
  const navigate = useNavigate();
  return (
    <>
      <ChangeLanguage />
      <TermsElement
        onClick={() => {
          ReactGATracker(EventType.LOGIN_VIEW_TERMS_CONDITIONS_CLICK);
          navigate("/terms");
        }}
      >
        Terms & Conditions
      </TermsElement>
      <SupportUs>
        <BottomTextContainer>
          <Trans i18nKey="support" />:
        </BottomTextContainer>
        <Contact>{supportNumber}</Contact>
      </SupportUs>
    </>
  );
};

export default LoginFooter;
