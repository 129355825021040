import {
  Button as AntdButton,
  Input as AntdInput,
  Table as AntdTable,
  Modal,
  Select
} from "antd";
import COLORS from "common/constants/Colors";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const LinkElement = styled(Link)`
  margin: 0 20px 0 0;
`;

export const CommonHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin: 20px 0;
`;

export const Button = styled(AntdButton)`
  background: "white";
  color: ${COLORS.primary_green};
  font-size: 14px;
  font-weight: bold;
  min-width: 60px;
  padding: 0.25em 1em;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 5px;
  ${(props) =>
    props.primary &&
    css`
      color: white;
      background: ${COLORS.primary_green};
      font-size: 16px;
      font-weight: 600;
      height: 50px;
      :hover {
        background: ${COLORS.primary_green};
        color: white;
      }
    `}
`;

export const DynamicColorButton = styled.button`
  background-color: ${(props) => props?.color ?? "black"};
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  border: 0px;
  cursor: pointer;
`;

export const ColorAntdButton = styled(Button)`
  border-color: ${(props) => props.color || COLORS.primary_green};
  color: ${(props) => props.color || COLORS.primary_green};
  :hover {
    border-color: ${(props) => props.color || COLORS.primary_green};
    color: ${(props) => props.color || COLORS.primary_green};
  }
`;

export const CustomAntdModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-header {
    border-radius: 20px 20px 0 0;
  }
`;

export const CustomAntdTable = styled(AntdTable)`
  .ant-table-container {
    border: 1px solid ${COLORS.primary_grey} !important;
  }
  .ant-table-thead > tr > th {
    background-color: white;
    text-transform: capitalize;
    border-bottom: 1px solid ${COLORS.primary_grey};
    padding: 5px 15px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${COLORS.primary_grey};
    padding: 5px 15px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: white;
  }
  .ant-table-container table > tbody > tr:last-child > td {
    border-bottom: 0px !important;
  }
`;

export const StyledAntdTable = styled(AntdTable)`
  .ant-table-container {
    border: 1px solid rgba(0, 87, 84, 0.2) !important;
  }
  .ant-table-thead > tr > th {
    text-align: center;
    background: linear-gradient(
        0deg,
        rgba(1, 191, 185, 0.05) 0%,
        rgba(1, 191, 185, 0.05) 100%
      ),
      #fff;
    padding: 10px;
    color: #2e2e2e;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    border-right: 1px solid rgba(0, 87, 84, 0.2);
    white-space: nowrap;
    @media screen and (max-width: 1280px) and (min-width: 1024px) {
      font-size: 12px;
    }
    @media screen and (max-width: 1023px) {
      font-size: 10px;
      padding: 8px;
    }
  }
  .ant-table-tbody > tr > td {
    background: #fff;
    border-top: 1px solid rgba(0, 87, 84, 0.2);
    border-right: 1px solid rgba(0, 87, 84, 0.2);
    color: #2e2e2e;
    padding: 0;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    @media screen and (max-width: 1280px) and (min-width: 1024px) {
      font-size: 12px;
    }
    @media screen and (max-width: 1023px) {
      font-size: 10px;
    }
  }
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #e2eafc;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #01bfb9;
    color: white;
  }
`;

export const StyledAntdModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    border: 1.5px solid rgba(0, 87, 84, 0.4);
  }
  .ant-modal-header {
    border-radius: 12px 12px 0 0;
    padding-top: 18px;
    border-bottom: 1px solid rgba(0, 87, 84, 0.2);
  }
  .ant-modal-title {
    color: #005754;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const StyledButton = styled.button`
  ${(props) => {
    if (props.size === "small") {
      return css`
        padding: 0 14px;
        font-size: 12px;
        margin: 2px 0;
      `;
    }
    return css`
      padding: 12px 28px;
      font-size: 16px;
    `;
  }}
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
  ${(props) => {
    if (props.type === "danger") {
      return css`
        border-radius: 4px;
        border: 1px solid rgba(142, 21, 21, 0.2);
        background: linear-gradient(94deg, #ee9892 0%, #e55f56 100%);
      `;
    }
    if (props.type === "primary") {
      return css`
        background: linear-gradient(
          275deg,
          #00bfb9 0%,
          rgba(0, 191, 185, 0.65) 100%
        );
        border-radius: 6px;
        border: 1.5px solid rgba(0, 148, 143, 0.3);
      `;
    }
    return css`
      background: white;
      border-radius: 6px;
      border: 1.5px solid rgba(0, 148, 143, 0.3);
      color: ${COLORS.dark_teal};
    `;
  }};
  opacity: ${(props) => props.disabled && "0.4"};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border: none;
  :focus-within {
    border: 1px solid black;
  }
  @media screen and (max-width: 1279px) {
    font-size: 11px;
    ${(props) => {
      if (props.size === "small") {
        return css`
          padding: 0 14px;
          font-size: 11px;
          padding: 0 4px;
          margin: 2px 0;
        `;
      }
      return css`
        padding: 8px 20px;
        font-size: 12px;
      `;
    }}
  }
  .ant-spin-dot-spin {
    margin-right: 4px;
  }
  .ant-spin-dot-item {
    background-color: #fff;
  }
`;

export const StyledSelect = styled(Select)`
  color: #005754;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15.084px; /* 125.698% */
  border-radius: 8px;
  border: 1px solid rgba(0, 87, 84, 0.1);
  background: linear-gradient(180deg, #fff 0%, #f5f5f5 100%);
  box-shadow: 0px 4px 6px -3px rgba(154, 188, 187, 0.6);
  :focus-within {
    border: 1px solid rgba(0, 87, 84, 0.5);
  }
`;

export const StyledInput = styled(AntdInput)`
  outline: none;
  border-radius: 4px;
  border: 1px solid rgba(0, 87, 84, 0.5);
  background: linear-gradient(180deg, #fff 0%, #f5f5f5 100%);
  color: rgba(0, 87, 84, 0.8);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  @media screen and (max-width: 1279px) {
    font-size: 12px;
  }
`;

const ColumnContainer = styled.div`
  text-align: left;
  padding-left: 20px;
  ${(props) =>
    props.trim &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
  color: ${(props) => props.color || "#005754"};
  @media screen and (max-width: 1279px) {
    padding-left: 10px;
  }
`;
export const ColumnElement = (props) => {
  const { data, trim = false, color } = props;
  return (
    <ColumnContainer trim={trim} color={color}>
      {data}
    </ColumnContainer>
  );
};

export const Table = styled(AntdTable)`
  .ant-table table {
    border-collapse: collapse;
    margin: 20px 0px;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    > :last-child {
      border: 0px solid black;
      border-bottom: 1.2px solid #d4d4d4;
    }
  }
  thead {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
  }
  tr {
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid #d4d4d4;
  }
`;

export const ButtonFloatRightContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AbsoluteLeftContainer = styled.section`
  position: absolute;
  right: 0;
  padding: 2em;
`;

export const LargeButton = styled(Button)`
  height: 70px;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 6px;
  margin-top: 20px;
  :disabled {
    color: white;
    background: ${COLORS.primary_green};
    opacity: 0.7;
    border: 1.5px solid ${COLORS.primary_green};
    border-radius: 6px;
    :hover {
      background: ${COLORS.primary_green};
      color: white;
    }
  }
`;

export const InputElement = styled.input`
  height: 50px;
  width: 100%;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 10px;
  outline: none;
  ${(props) =>
    (props.disabled &&
      css`
        background-color: ${COLORS.disabled_grey};
        border-color: ${COLORS.disabled_grey};
      `) ||
    (props.error &&
      css`
        border-color: red;
      `)}
`;

export const InputCheckboxElement = styled.input`
  height: 20px;
  width: 20%;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 10px;
  outline: none;
  ${(props) =>
    (props.disabled &&
      css`
        background-color: ${COLORS.disabled_grey};
        border-color: ${COLORS.disabled_grey};
      `) ||
    (props.error &&
      css`
        border-color: red;
      `)}
`;

export const ImageInputElement = styled.input`
  display: flex;
  flex-wrap: wrap;
  height: 68px;
  width: 68px;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${COLORS.disabled_grey};
      border-color: ${COLORS.disabled_grey};
    `}
`;

export const ImageElement = styled.img`
  display: flex;
  flex-wrap: wrap;
  height: 68px;
  width: 68px;
  border: 1.5px solid ${COLORS.primary_green};
  border-radius: 6px;
  margin-bottom: 10px;
`;

export const TitleElement = styled.h1`
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  font-weight: bold;
  letter-spacing: 0;
`;

export const ErrorElement = styled.div`
  font-size: 12px;
  color: red;
`;

export const PageHeader = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: black;
`;

export const PageContainer = styled.div`
  margin: 10px;
  margin-left: 20px;
  margin-right: 70px;
`;

export const IconButton = styled.button`
  background: white;
  border: 0px;
  cursor: pointer;
  padding-left: 0px;
`;

export const Separator = styled.hr`
  margin-top: 20px;
  margin-left: 0px;
  width: 90%;
  border: 1px solid ${COLORS.primary_grey};
`;

export const ErrorMessage = styled.div`
  margin-top: 5px;
  color: red;
`;

export const CommonTableContainer = styled.div`
  margin: 20px 0px;
  border: 1px solid ${COLORS.primary_grey};
  border-radius: 10px;
  width: ${(props) => props.width || "90%"};
  max-height: ${(props) => props.maxHeight || "70vh"};
  overflow: auto;
  > :last-child {
    border: 0px solid black;
  }
`;

export const CommonTableInput = styled.input`
  border: 1px solid ${COLORS.primary_grey};
  border-radius: 4px;
  text-align: center;
  width: 85%;
  ${(props) =>
    props.invalid &&
    css`
      color: red;
      border: 1px solid red;
      border-radius: 4px;
    `}
`;

export const CommonSearchInput = styled.input`
  color: ${COLORS.primary_green};
  ::placeholder {
    color: ${COLORS.primary_green};
  }
  border: 1px solid ${COLORS.primary_green};
  border-radius: 4px;
  padding: 10px;
  width: 25%;
  height: 35px;
  &:focus {
    border: 2px solid ${COLORS.primary_green} !important;
    outline: none;
  }
`;

export const CommonTableHeading = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

export const CommonSelectHeading = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
`;

export const CommonTableHeader = styled.div`
  display: grid;
  padding: 20px;
  align-items: center;
  text-align: center;
  justify-items: center;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  border-bottom: 1px solid ${COLORS.primary_grey};
  position: sticky;
  top: 0px;
  background: ${(props) => props?.background ?? "white"}; ;
`;

export const CommonTableRowContainer = styled.div`
  display: grid;
  padding: 15px;
  align-items: center;
  text-align: center;
  justify-items: center;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  border-bottom: 1px solid ${COLORS.primary_grey};
`;

export const WelcomeTextElement = styled.div`
  font-size: 40px;
  font-weight: bold;
  align-self: center;
`;
export const EnneaLogoElement = styled.img`
  height: 82px;
  width: 140px;
  align-self: center;
`;
export const SignInImageElement = styled.img`
  margin-left: 5%;
  align-self: center;
  width: 50%;
`;

export const InputFileLabel = styled.label`
  border: 1px solid ${COLORS.primary_green};
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  color: ${COLORS.primary_green};
  font-weight: bold;
`;
