import { Image, message } from "antd";
import {
  CustomAntdModal,
  Button as DeleteImageButton,
  ErrorElement,
  ImageInputElement,
  InputElement,
  LargeButton,
  TitleElement
} from "common/components/StyledElements";
import COLORS from "common/constants/Colors";
import {
  emailRegex,
  gstRegex,
  phoneNumberRegex,
  pinCodeRegex
} from "common/constants/regex";
import Check from "common/icons/CheckGreen.svg";
import UnCheck from "common/icons/UncheckGreen.svg";
import backArrow from "common/icons/backArrow.svg";
import PdfIcon from "common/icons/pdfFile.svg";
import { validateNumberInput } from "common/utils";
import { signupAction } from "common/utils/APIMethods";
import { EventType, ReactGATracker } from "common/utils/analyticsTracker";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const PageContainer = styled.body`
  height: 100%;
  background: ${COLORS.login_body_gradient};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50%;
  justify-content: flex-start;
  height: auto;
  margin: 0 0 0 5%;
`;
const HeaderContainer = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 5px;
  font-weight: bold;
  font-size: 22px;
  gap: 20px;
`;
const RegisterButton = styled(LargeButton)`
  left: 5%;
  bottom: 20px;
  width: 30%;
  :disabled {
    opacity: 0.4;
  }
`;
const InputFileLabel = styled.label`
  border: 1px solid ${COLORS.primary_green};
  border-radius: 5px;
  text-align: center;
  width: 34%;
  padding: 10px;
  cursor: pointer;
  color: ${COLORS.primary_green};
  font-weight: bold;
`;
const PreviewInfo = styled.div`
  font-size: 12px;
  width: fit-content;
  margin-left: 8px;
`;
const Img = styled.img`
  width: 68px;
  height: 68px;
  cursor: pointer;
`;
const BackImg = styled.img`
  margin-left: 8px;
  width: 35px;
  height: 35px;
  cursor: pointer;
`;
const ImageContainer = styled.div`
  margin-top: 20px;
`;
const ConsentContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
`;
const CheckBoxImg = styled.img`
  width: 18px;
  height: 18px;
`;
const ConsentText = styled.div`
  color: ${(props) => (props.checked ? "#005754" : "red")};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-transform: capitalize;
  white-space: nowrap;
`;
const TermsElement = styled.u`
  height: 40px;
  cursor: pointer;
  margin: 6px 28px 0;
  color: ${COLORS.primary_green};
`;

const Signup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [drugLicenseImage, setDrugLicenseImage] = useState(null);
  const [showPdfPreview, setShowPdfPreview] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [
    isTermsAndConditionConsentChecked,
    setIsTermsAndConditionConsentChecked
  ] = useState(false);
  const [isWhatsappConsentChecked, setIsWhatsappConsentChecked] =
    useState(false);
  const [existingUserModalVisible, setExistingUserModelVisible] =
    useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    setValue
  } = useForm({
    mode: "onChange",
    defaultValues: {
      promoCode: "",
      contactPerson: "",
      phoneNumber: "",
      pinCode: "",
      emailId: "",
      dl: "",
      gstin: ""
    }
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords } = position;
          ReactGATracker(EventType.REGISTRATION_LOCATION_PERMISSION_STATUS);
          setUserLocation({
            latitude: coords.latitude,
            longitude: coords.longitude
          });
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const mutation = useMutation((formData) => signupAction(formData), {
    onSuccess: (response) => {
      if (response?.phoneNumber) {
        message.success(response?.message);
        navigate("/login", {
          state: {
            phoneNumber: response?.phoneNumber,
            isAutoOtpGeneration: true
          },
          replace: true
        });
      } else {
        message.success(response?.message);
        navigate("/login", {
          state: { username: response?.username },
          replace: true
        });
      }
    },
    onError: (error) => {
      if (error?.message === "Duplicate phone number") {
        ReactGATracker(
          EventType.REGISTRATION_PHONE_NUMBER_EXISTING_MODAL_VISIBLE
        );
        setExistingUserModelVisible(true);
      } else {
        message.error(error.message);
      }
    }
  });

  const onSubmit = (formData) => {
    const { dl, ...registrationData } = formData;
    const registrationDataWithLocation = {
      ...registrationData,
      location: userLocation
    };
    const newFormData = new FormData();
    newFormData.append(
      "registrationRequest",
      JSON.stringify(registrationDataWithLocation)
    );
    newFormData.append("file", drugLicenseImage?.file || "");
    ReactGATracker(EventType.REGISTRATION_BUTTON_CLICK);
    mutation.mutate(newFormData);
  };

  const registerOptions = {
    promoCode: {},
    ownerName: {
      required: t("enter_owner")
    },
    phoneNumber: {
      required: t("enter_your_phone"),
      pattern: {
        value: phoneNumberRegex,
        message: t("enter_valid_phone")
      }
    },
    pinCode: {
      required: t("enter_pincode"),
      pattern: {
        value: pinCodeRegex,
        message: t("enter_valid_pincode")
      }
    },
    email: {
      required: t("enter_email"),
      pattern: { value: emailRegex, message: t("enter_valid_email") }
    },
    dl: {
      required: t("submit_drug_license")
    },
    gstin: {
      pattern: { value: gstRegex, message: t("enter_valid_gst") }
    }
  };

  return (
    <PageContainer>
      <HeaderContainer>
        <BackImg src={backArrow} onClick={() => navigate(-1)} alt="back" />
        <Trans i18nKey="registration" />
      </HeaderContainer>
      <br />
      <Container>
        <TitleElement>
          <Trans i18nKey="owner_name" />*
        </TitleElement>
        <InputElement
          {...register("contactPerson", registerOptions.ownerName)}
          placeholder={t("enter_owner_name")}
          error={errors?.contactPerson}
          onBlur={(e) => setValue("contactPerson", e.target.value.trim())}
          onFocus={() => {
            ReactGATracker(EventType.REGISTRATION_OWNER_NAME_FIELD_CLICK);
          }}
        />
        {errors.contactPerson && (
          <ErrorElement>{errors.contactPerson.message}</ErrorElement>
        )}
        <TitleElement>
          <Trans i18nKey="phone_number" />*
        </TitleElement>
        <InputElement
          type="number"
          onKeyDown={validateNumberInput}
          {...register("phoneNumber", registerOptions.phoneNumber)}
          error={errors?.phoneNumber}
          placeholder={t("enter_phone_number")}
          onFocus={() => {
            ReactGATracker(EventType.REGISTRATION_PHONE_NUMBER_FIELD_CLICK);
          }}
        />
        {errors.phoneNumber && (
          <ErrorElement>{errors.phoneNumber.message}</ErrorElement>
        )}
        <TitleElement>
          <Trans i18nKey="pincode" />*
        </TitleElement>
        <InputElement
          type="number"
          onKeyDown={validateNumberInput}
          {...register("pinCode", registerOptions.pinCode)}
          error={errors?.pinCode}
          placeholder={t("enter_pincode")}
          onFocus={() => {
            ReactGATracker(EventType.REGISTRATION_PINCODE_FIELD_CLICK);
          }}
        />
        {errors.pinCode && (
          <ErrorElement>{errors.pinCode.message}</ErrorElement>
        )}
        <TitleElement>
          <Trans i18nKey="emailid" />*
        </TitleElement>
        <InputElement
          type="email"
          {...register("emailId", registerOptions.email)}
          error={errors?.emailId}
          placeholder={t("enter_emailid")}
          onFocus={() => {
            ReactGATracker(EventType.REGISTRATION_EMAIL_ID_FIELD_CLICK);
          }}
        />
        {errors.emailId && (
          <ErrorElement>{errors.emailId.message}</ErrorElement>
        )}
        <TitleElement>GST</TitleElement>
        <InputElement
          {...register("gstin", registerOptions.gstin)}
          error={errors?.gstin}
          placeholder={t("enter_gstin")}
          onBlur={(e) => setValue("gstin", e.target.value.trim())}
          onFocus={() => {
            ReactGATracker(EventType.REGISTRATION_GST_NUMBER_FIELD_CLICK);
          }}
        />
        <TitleElement>Referral Code</TitleElement>
        <InputElement
          {...register("promoCode", registerOptions.promoCode)}
          placeholder={t("enter_referral_code")}
          onBlur={(e) => setValue("promoCode", e.target.value.trim())}
          onFocus={() => {
            ReactGATracker(EventType.REGISTRATION_REFERRAL_CODE_FIELD_CLICK);
          }}
        />
        {errors.gstin && <ErrorElement>{errors.gstin.message}</ErrorElement>}
        <TitleElement>
          <Trans i18nKey="upload_drug_license" />*
        </TitleElement>
        <InputFileLabel
          {...register("dl", registerOptions.dl)}
          htmlFor="fileUpload"
        >
          <Trans i18nKey="upload_file" />
        </InputFileLabel>
        <ImageInputElement
          id="fileUpload"
          type="file"
          style={{ display: "none" }}
          accept="image/*,application/pdf"
          placeholder={t("upload_image")}
          onChange={(e) => {
            ReactGATracker(EventType.REGISTRATION_UPLOAD_DRUG_LICENSE_CLICK);
            setDrugLicenseImage({
              name: e.target?.files[0]?.name,
              image: URL.createObjectURL(e.target?.files[0]),
              file: e.target?.files[0],
              type: e.target?.files[0].type
            });
            setValue("dl", e.target?.files[0]?.name, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true
            });
          }}
        />
        {errors.dl && <ErrorElement>{errors.dl.message}</ErrorElement>}
        {drugLicenseImage && (
          <ImageContainer>
            {drugLicenseImage.type === "application/pdf" ? (
              <>
                <PreviewInfo>Tap to preview</PreviewInfo>
                <Img
                  src={PdfIcon}
                  onClick={() => setShowPdfPreview(true)}
                  alt=""
                />
              </>
            ) : (
              <Image
                alt="DRUG LICENSE"
                width={68}
                height={68}
                src={drugLicenseImage.image}
              />
            )}
            <h5>{drugLicenseImage.name}</h5>
            <DeleteImageButton
              onClick={() => {
                ReactGATracker(
                  EventType.REGISTRATION_REMOVE_DRUG_LICENSE_CLICK
                );
                setValue("dl", null);
                setDrugLicenseImage(null);
              }}
            >
              <Trans i18nKey="remove_file" />
            </DeleteImageButton>
          </ImageContainer>
        )}
        <ConsentContainer
          onClick={() =>
            setIsWhatsappConsentChecked((state) => {
              if (state) {
                ReactGATracker(
                  EventType.REGISTRATION_AGREE_FOR_WHATSAPP_NOTIFICATIONS_CLICK
                );
              }
              return !state;
            })
          }
        >
          <CheckBoxImg
            src={isWhatsappConsentChecked ? Check : UnCheck}
            alt="check"
          />
          <ConsentText checked={isWhatsappConsentChecked}>
            {t("whatsapp_consent_checkbox")}
          </ConsentText>
        </ConsentContainer>
        <ConsentContainer
          onClick={() =>
            setIsTermsAndConditionConsentChecked((state) => {
              ReactGATracker(
                EventType.REGISTRATION_AGREE_FOR_TERMS_CONDITIONS_CLICK
              );
              return !state;
            })
          }
        >
          <CheckBoxImg
            src={isTermsAndConditionConsentChecked ? Check : UnCheck}
            alt="check"
          />
          <ConsentText checked={isTermsAndConditionConsentChecked}>
            {t("terms_of_service_checkbox")}
          </ConsentText>
        </ConsentContainer>
        <TermsElement
          onClick={() => {
            ReactGATracker(EventType.REGISTRATION_VIEW_TERMS_CONDITIONS_CLICK);
            navigate("/terms");
          }}
        >
          Terms & Conditions
        </TermsElement>
      </Container>
      <RegisterButton
        primary="true"
        loading={mutation.isLoading}
        onClick={handleSubmit(onSubmit)}
        disabled={
          !(isTermsAndConditionConsentChecked && isWhatsappConsentChecked)
        }
      >
        <Trans i18nKey="register" />
      </RegisterButton>
      <CustomAntdModal
        onCancel={() => {
          setExistingUserModelVisible(false);
          ReactGATracker(
            EventType.REGISTRATION_PHONE_NUMBER_EXISTING_MODAL_CLOSED
          );
        }}
        onOk={() => {
          ReactGATracker(
            EventType.REGISTRATION_PHONE_NUMBER_EXISTING_MODAL_RESET_CLICK
          );
          navigate("/reset", {
            state: { phNumber: getValues("phoneNumber") },
            replace: true
          });
        }}
        maskClosable={false}
        open={existingUserModalVisible}
        title={`${t("already_registered_phone_number")}`}
        centered
        width={1000}
      >
        <div>
          <p>{t("click_on_OK_to_reset_the_password")}</p>
        </div>
      </CustomAntdModal>
      <CustomAntdModal
        open={showPdfPreview}
        title="PDF Preview"
        centered
        onCancel={() => setShowPdfPreview(false)}
        footer={null}
        width="60%"
      >
        <embed
          src={drugLicenseImage?.image}
          width="100%"
          height="500"
          type="application/pdf"
        />
      </CustomAntdModal>
    </PageContainer>
  );
};

export default Signup;
